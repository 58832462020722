// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React layouts
import Dashboard from "../layouts/dashboard";
import UsersList from "../layouts/user/usersList";
import SignIn from "../layouts/authentication/sign-in";
import ResetPassword from "../layouts/authentication/reset-password/cover";
import HoodsList from "../layouts/hoods/hoodsList";
import AdminsList from "../layouts/admin/adminsList";
import CmsList from "../layouts/cms/cmsList";
import CmsEdit from "../layouts/cms/cmsEdit";
import CountryList from "../layouts/country/countryList";
import PlacesList from "../layouts/places/placesList";
import Country from "../layouts/country/country";
import Settings from "../layouts/settings/settings";
import GeneralSettings from "../layouts/settings/general";
import HoodSettings from "../layouts/settings/hoods";
import PlaceSettings from "../layouts/settings/Places";
import LabelLanguageSettings from "../layouts/settings/LabelLanguage";
import Admin from "../layouts/admin/admin";
import User from "../layouts/user/user";
import Hood from "../layouts/hoods/hood";
import TermAndCondition from "../layouts/cms/termAndCondition";
import PrivacyPolicy from "../layouts/cms/privacyPolicy";
import Place from "../layouts/places/place";
import DeleteUserList from "../layouts/deletedUsers/deletedUsersList";
import ForgotPassword from "../layouts/authentication/forgot-password";
import Post from "../layouts/hoods/post";
import NotFound from "../layouts/404NotFoundPage/notFound";
import PostsList from "../layouts/posts/postList";
import PostsDetails from "../layouts/posts/posts";
import ResetPasswordApp from "../layouts/authentication/reset-password-app";
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    type: "collapse",
    name: "App Users",
    key: "users",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/users",
    component: <UsersList />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    // type: "collapse",
    name: "User Details",
    key: "user-details",
    route: "/user/:id/:action",
    component: <User />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    type: "collapse",
    name: "Hoods",
    key: "hoods",
    icon: <Icon fontSize="small">groups_icon</Icon>,
    route: "/hoods",
    component: <HoodsList />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    // type: "collapse",
    name: "Hood",
    key: "hoods-action",
    route: "/hoods/:id/:action",
    component: <Hood />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    name:"Post",
    key:"post",
    route:"/hoods/:id/post",
    component:<Post/>,
    role:["SUPER_ADMIN","ADMIN"],
    authenticated:true
  },
  {
    // type: "collapse",
    name: "Posts",
    key: "posts-action",
    route: "/posts/:id/:action",
    component: <PostsDetails />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    type: "collapse",
    name: "Posts",
    key: "state",
    icon: <Icon fontSize="small">list_icon</Icon>,
    route: "/posts",
    component:<PostsList/>,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    type: "collapse",
    name: "Places",
    key: "places",
    icon: <Icon fontSize="small">place_icon</Icon>,
    route: "/places",
    component: <PlacesList />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    // type: "collapse",
    name: "Places",
    key: "places-action",
    route: "/places/:id/:action",
    component: <Place />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    // type: "collapse",
    name: "Logout",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/admin/signin",
    component: <SignIn />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: false,
  },
  {
    // type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/admin/resetpassword/:token",
    component: <ResetPassword />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: false,
  },
  {
    // type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/admin/forgotpassword",
    component: <ForgotPassword/>,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: false,
  },
  {
    type: "collapse",
    name: "Admins",
    key: "admins",
    icon: <Icon fontSize="small">group_icon</Icon>,
    route: "/admins",
    component: <AdminsList />,
    role: ["SUPER_ADMIN"],
    authenticated: true,
  },
  {
    // type: "collapse",
    name: "Add-admin",
    key: "admin-add",
    route: "/admin/add",
    component: <Admin />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    // type: "collapse",
    name: "Admin",
    key: "admin-action",
    route: "/admin/:id/:action",
    component: <Admin />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    type: "collapse",
    name: "CMS",
    key: "cms",
    icon: <Icon fontSize="small">policy_icon</Icon>,
    route: "/cms",
    component: <CmsList />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    // type: "collapse",
    name: "CMS-Edit",
    key: "cms-edit",
    route: "/cms/:id/edit",
    component: <CmsEdit />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    // type: "collapse",
    name: "Term-And-Condition",
    key: "Term-And-Condition",
    route: "/term-condition",
    component: <TermAndCondition />,
    // authenticated: true,
  },
  {
    // type: "collapse",
    name: "Privacy-And-Policy",
    key: "Privacy-And-Policy",
    route: "/privacy-policy",
    component: <PrivacyPolicy />,
    // authenticated: true,
  },
  {
    type: "collapse",
    name: "Country",
    key: "country",
    icon: <Icon fontSize="small">public_icon</Icon>,
    route: "/country",
    component: <CountryList />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    name: "Add-country",
    key: "add-country",
    route: "/country/add",
    component: <Country />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    name: "Country",
    key: "country-action",
    route: "/country/:id/:action",
    component: <Country />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    type: "collapse",
    name: "Delete Users",
    key: "delete-Users",
    icon: <Icon fontSize="small">personremove_icon</Icon>,
    route: "/deleteUser",
    component: <DeleteUserList />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings_icon</Icon>,
    route: "/settings",
    component: <Settings />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    name: "Settings General",
    key: "settings-general",
    route: "/settings/general",
    component: <GeneralSettings />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    name: "Settings Hoods",
    key: "settings-hoods",
    route: "/settings/hoods",
    component: <HoodSettings />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    name: "Settings Places",
    key: "settings-places",
    route: "/settings/places",
    component: <PlaceSettings />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    name: "Settings LabelLanguage",
    key: "settings-labelLanguage",
    route: "/settings/labelLanguage",
    component: <LabelLanguageSettings />,
    role: ["SUPER_ADMIN", "ADMIN"],
    authenticated: true,
  },
  {
    name:"Not Found",
    key:"not-found",
    route:"*",
    component:<NotFound/>,
  },
  {
    type: "collapse",
    name: "App-Reset-Password",
    route: "/app/resetpassword/:token",
    component: <ResetPasswordApp />,
    
  }
];

export default routes;
