import { Autocomplete, createFilterOptions, TextField, Checkbox, Radio, MenuItem, Select } from "@mui/material";
import MDInputRoot from "../components/MDInput/MDInputRoot";
// import MuiSelect from "../assets/theme/components/form/select";
import { get as _get, isUndefined as _isUndefined, isEmpty as _isEmpty } from "lodash";

export const renderAutoComplete = ({
  field,
  form: { initialValues, setFieldValue, touched, errors },
  ...props
}) => {
  // console.log('renderAutoComplete::: form object::', field, props);
  return (
    <Autocomplete
      freeSolo={props.freeSolo}
      sx={props.style}
      disabled={props.disabled}
      value={field.value}
      inputValue={props.inputValue}
      onInputChange={(event, value) => {
        props.onInputChange && props.onInputChange(value);
      }}
      onChange={(event, value) => {
        if (value !== null) {
          if (props.handleChange) {
            props.handleChange(value);
            setFieldValue(field.name, value);
          } else {
            setFieldValue(field.name, value);
          }
        } else {
          setFieldValue(field.name, initialValues[field.name]);
        }
      }}
      disableClearable={props.disableClearable}
      filterSelectedOptions={props.filterSelectedOptions}
      renderOption={props.renderOption}
      filterOptions={createFilterOptions((option) => option)}
      loading={
        !props.hideLoading && _get(props.menuoptionlist, "length", 0) === 0
      }
      getOptionLabel={
        props.getOptionLabel ||
        ((option) => {
          console.log("option fieldcmt",option)
          return !_isUndefined(_get(option, props.displayLabel))
            ? _get(option, props.displayLabel)
            : option
        })
      }
      options={props.menuoptionlist}
      PaperComponent={props.newadditem}
      renderInput={(params) => {
        // console.log("renderInput params ::", params)
        return (
          <TextField
            {...params}
            label={props.label}
            name={props.name}
            helperText={touched[field.name] && errors[field.name]}
            error={Boolean(touched[field.name] && errors[field.name])}
            type={props.type}
            fullWidth={props.fullWidth}
            style={props.style}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
            {...field}
          />
        );
      }}
    />
  );
};

export const renderTextField = ({
  field,
  form: { touched, errors, handleChange, success, disabled },
  ...props
}) => {
  // console.log('renderTextField called:::', props, field);
  return (
    <MDInputRoot
      helperText={touched[field.name] && errors[field.name]}
      autoFocus={props.autoFocus}
      autoComplete={props.autoComplete ? props.autoComplete : "off"}
      margin="normal"
      error={Boolean(touched[field.name] && errors[field.name])}
      onChange={handleChange}
      variant="outlined"
      disabled={props.disabled}
      label={props.label}
      type={props.type}
      fullWidth={props.fullWidth}
      InputProps={props.InputProps}
      InputLabelProps={{ shrink: !field.value ? false : true }}
      style={props.style}
      ownerState={{ errors, success, disabled }}
      {...field}
      {...props}
    />
  );
};


export const renderCheckbox = ({ field, form: { setFieldValue, initialValues }, ...props }) => (
  <Checkbox
    checked={Boolean(field.value)}
    onChange={(event) => {
      if (field.value !== undefined) {
        if (props.handleChange) {
          props.handleChange(event.target.checked);
          setFieldValue(field.name, Boolean(event.target.checked));
        } else {
          setFieldValue(field.name, Boolean(event.target.checked));
        }
      } else if (initialValues[field.name] !== undefined) {
        setFieldValue(field.name, initialValues[field.name]);
      } else {
        setFieldValue(field.name, Boolean(event.target.checked));
      }
    }}
    disabled={props.disabled}
    color={props.color}
    size={props.size}
    sx={props.style}
    {...props}
  />
)

export const renderRadioButton = ({ field, form: { setFieldValue, initialValues }, ...props }) => {
 
  //console.log('renderRadioButton::', field, props)
  return (

  <Radio
    checked={Boolean(props.checked) || Boolean(field.value) }
    onChange={(event) => {
      if (props.checked !== undefined  || field.value !== undefined) {
        if (props.handleChange) {
          props.handleChange(event.target.checked);
          setFieldValue(field.name, Boolean(event.target.checked));
        } else {
          setFieldValue(field.name, Boolean(event.target.checked));
        }
      } else if (initialValues[field.name] !== undefined) {
        setFieldValue(field.name, initialValues[field.name]);
      } else {
        setFieldValue(field.name, Boolean(event.target.checked));
      }
    }}
    disabled={props.disabled}
    color={props.color}
    size={props.size}
    sx={props.style}
    {...props}
  />

)}

export const renderSelectField = ({ field, form: {setFieldValue}, ...props }) => {
    return (
      field.value !== undefined &&
        <Select
          disabled={props.disabled}
          label={props.label}
          labelId={props.labelId}
          value={field.value}
          defaultValue={props.defaultValue}
          autoWidth={props.autoWidth}
          onChange={(event) => {
            if (props.handleChange) {
               props.handleChange(event.target.value);
               setFieldValue(field.name, event.target.value);
            } else {
              setFieldValue(field.name, event.target.value);
            }
          }}
         {...props}
        >
        {
          //handel the array of object
          (props.menuItemArray.length > 0 && props.menuItemArray.filter(value => typeof value === 'object').length > 0) ?
            props.menuItemArray.map((item, idx) => (
              <MenuItem value={item._id} key={idx}> {item.label}</MenuItem>
            ))
          :
            //handel the array
            (props.menuItemArray.length > 0 &&
              props.menuItemArray.map((item, idx) => (
              <MenuItem value={item} key={idx}> {item} </MenuItem>
            )))
        } 
      </Select>
    )
  }

