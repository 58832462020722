import { call, put, takeLatest } from "redux-saga/effects";
import { saveLanguageList } from "../actions/language";
import mias from "../api";
import { get as _get } from "lodash";
import { GET_LANGUAGE_LIST, SAVE_LANGUAGE_LIST } from "../types/action-type";

function* getLanguageList() {
  try {
    const response = yield call(mias.apis.language.getLanguageList);
    // console.log("check Language list ::", response);
    yield put(saveLanguageList(_get(response, "data.data", [])));
  } catch (err) {
    console.log("get Language list saga error ::", err);
  }
}

export default function* language() {
  yield takeLatest(GET_LANGUAGE_LIST, getLanguageList);
  yield takeLatest(SAVE_LANGUAGE_LIST, saveLanguageList);
}
