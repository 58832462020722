import { GET_LANGUAGE_LIST, SAVE_LANGUAGE_LIST } from "../types/action-type";

export const getLanguageList = () => ({
  type: GET_LANGUAGE_LIST,
});

export const saveLanguageList = (data) => ({
  type: SAVE_LANGUAGE_LIST,
  data,
});
