import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import DataTable from "../../../examples/Tables/DataTable/serverTable";
import IconButton from '@mui/material/IconButton';

import pxToRem from "../../../../src/assets/theme/functions/pxToRem";
import DeleteIcon from "@mui/icons-material/Delete";

import CloseIcon from '@mui/icons-material/Close';

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  Switch,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get as _get,
  find as _find,
  map as _map,
  omit as _omit,
  isEmpty as _isEmpty,
} from "lodash";
import MDTypography from "../../../components/MDTypography";
import {
  getLabelLanguageAddRequest,
  getLabelLanguageDeleteRequest,
  getLabelLanguageById,
  getLabelLanguageUpdateRequest,
  getLabelLanguageStatusUpdateRequest
} from "../../../actions/labelLanguage";
import { Field, FieldArray, Form, Formik } from "formik";
import MDButton from "../../../components/MDButton";
import { getLanguageList } from "../../../actions/language";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { showError } from "../../../utils";

const LabelLanguage = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [key, setkey] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [newLabel, setNewLabel] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  useEffect(() => {
    dispatch(getLanguageList());
  }, [dispatch]);

  const storeProps = useSelector((state) => {
    console.log("labelLanguage", state);
    return {
      data: _get(state, "labelLanguage.labelLanguageList", []),
      key: _get(state, "labelLanguage.labelLanguageList.key", ""),
      label: _get(state, "labelLanguage.labelLanguageData.label", []),
      code: _get(state, "labelLanguage.placeCategoryData.code", ""),
      status: _get(state, "places.placeCategoryData.status", false),
      languageList: _get(state, "language.list", []),
      totalRecords: _get(state,'labelLanguage.pagination.totalRecord', 0),
      pageIndex: _get(state,'labelLanguage.pagination.pageIndex', 0),
      pageSize: _get(state,'labelLanguage.pagination.pageSize', 10),
    };
  });

   const initialValues = {
    code: "",
    key: "",
    label: [
      {
        local: "",
        name: "",
        languageName: "",
      },
    ],
  };

  const getTitleName = (label) =>
    _map(label, (lab) => ({ name: lab.name }))[0].name;

  const handleChangeStatus = (value, key) => {
    const statusData = { key: key, status: value };
     dispatch(getLabelLanguageStatusUpdateRequest(statusData));
  };

  const tableData = {
    columns: [
      {
        Header: "title",
        accessor: "label",
        Cell: (row) => (
          <Tooltip title={getTitleName(_get(row, "row.original.label"))}>
            <MDTypography
              // noWrap
              // display="block"
              // variant="button"
              // fontWeight="medium"
              // textTransform="capitalize"
              // style={{
              //   overflow: "hidden",
              //   textOverflow: "ellipsis",
              //   width: "12rem",
              // }}
              variant="caption" color="text" fontWeight="regular" fontSize="14px" 
            >
              {getTitleName(_get(row, "row.original.label"))}
            </MDTypography>
          </Tooltip>
        ),
        align: "left",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
      },
      {
        Header: "Label key",
        accessor: "Label key",
        Cell: (row) => (
          <Tooltip title={_get(row, "row.original.key")}>
            <MDTypography
              variant="caption" color="text" fontWeight="regular" fontSize="14px"
            >
              {_get(row, "row.original.key")}
            </MDTypography>
          </Tooltip>
        ),
        align: "left",
      },
      {
        Header: "status",
        accessor: "status",
        Cell: (row) => (
          <Switch
            checked={_get(row, "row.original.status")}
            onChange={(e, value) =>
              handleChangeStatus(value, _get(row, "row.original.key"))
            }
          />
        ),
        align: "center",
        width: "5%",
      },
      {
        Header: "action",
        accessor: "action",
        Cell: (row) => (
          <MDBox width="100px" margin="auto">
            <IconButton sx={{ 
              color: "#54AF3A", 
              backgroundColor: "#E8FFE2", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              '&:hover' :{
                backgroundColor: "#e6f3e2"
              }
            }}>
              <Icon
                fontSize="small"
                onClick={() => handleClickOpen(_get(row, "row.original.key"))}
                sx={{ cursor: "pointer" }}
              >
                edit_icon
              </Icon>
            </IconButton>
            <IconButton sx={{ 
              color: "#F05A22", 
              backgroundColor: "#fde4db", 
              borderRadius: "6px" ,
              width: pxToRem(35),
              height: pxToRem(35),
              ml: 2,
              '&:hover' :{
                backgroundColor: "#fbcfbf"
              }
            }}>
              <Icon
                fontSize="small"
                onClick={() => {
                  setOpenConfirmation(true);
                  setDeleteId(_get(row, "row.original.key"));
                }}
                sx={{ cursor: "pointer" }}
              >
                delete
              </Icon>
            </IconButton>
          </MDBox>
        ),
        align: "center",
        width: "10%",
      },
    ],

    rows: _get(storeProps, "data", []),
  };
  const { columns, rows } = tableData;

  const handleClickOpen = (key) => {
    setOpen(true);
    setkey(key)
    dispatch(getLabelLanguageById(key));
  };

  const handleClose = () => {
    setOpen(false);
    setIsNew(false);
    setNewLabel([]);
  };

  const handleChange = (event, value, index, setFieldValue, formValues) => {
    let checkFormLabel;
    if (!_isEmpty(formValues.label)) {
      checkFormLabel = _find(formValues.label, (e) => e.local === value.local);
    }
    if (!checkFormLabel) setFieldValue(`label.${index}`, value);
    else toast.error("Same Language Not Allowed!");
  };

  const updateCategory = (values) => {
    const labelArr = _map(_get(values, "label"), (label) =>
      _omit(label, ["languageName"])
    );

    let placeCategoryData = {
      label: labelArr,
      key: key,
    };
    if (isNew) {
      placeCategoryData.label = labelArr;
      placeCategoryData.key = key;
      dispatch(getLabelLanguageAddRequest(placeCategoryData));
    } else {
      placeCategoryData.key = key;
      dispatch(getLabelLanguageUpdateRequest(placeCategoryData));
    }

    handleClose();
  };


  const labelledData = () => {
 
    _map(_get(storeProps, "languageList"), (langListObj) => {
      const obj = {
        local: langListObj.code,
        languageName: langListObj.label,
      };
      newLabel.push(obj);
    });
  };
  const addCategory = () => {
  dispatch(getLanguageList());
    setIsNew(true);
    labelledData();
    
     setkey("")
  };

  
  const schema = Yup.object().shape({
    label: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Required"),
        local: Yup.string().required("Required"),
      })
    ),
  });

  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
    setDeleteId("");
  };

  const deleteHoodCategory = () => {
    dispatch(getLabelLanguageDeleteRequest({ key: deleteId }));
    handleConfirmationClose();
  };

  const handleKeyChange = (e) => {
   e.preventDefault();
   setkey(e.target.value);
  }

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="space-between" textAlign="center">
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <MDBox
                  mx={0}
                  mt={0}
                  py={2}
                  px={2}               
                  mb={0}
                  variant="contained"
                  bgColor='rgba(0, 0, 0, 0.0)'
                  borderRadius="lg"
                  position="relative"
                  // coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  zIndex="1"
                  width="calc(100% - 250px)"
                >
                    <MDTypography variant="h5" color="black" pt={1.1}>Label Languages</MDTypography>
                    <MDButton size="medium"
                        variant="outlined"
                        color="orange" onClick={() => addCategory()}>
                      {" "}
                      <Icon fontSize="small">
                        add_icon
                      </Icon>{" "}
                      &nbsp; Add
                    </MDButton>
              </MDBox>
              <MDBox pt={3} mt={-12.5}>
                  <DataTable
                  table={{ columns, rows }}
                    isSorted={false}
                    showTotalEntries={true}
                    noEndBorder
                    canSearch
                    totalRecords={storeProps.totalRecords}
                    pageIndex1={storeProps.pageIndex}
                    pageSize1={storeProps.pageSize}
                    tableFor="labelLanguageList"
                    />
              </MDBox>
              <Dialog
                open={open || isNew}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
              >
                
                  <DialogTitle sx={{ m: 0, pr: 8 }}>
                    {isNew ? "Add Language" : "Edit Language"}
                    {handleClose ? (
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 10,
                          color: (theme) => theme.palette.grey[700],
                          backgroundColor: (theme) => theme.palette.grey[200],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                  </DialogTitle>

                
                <Divider sx={{ marginTop: "0", marginBottom: "10px" }} />
                <DialogContent sx={{ marginTop: 0 }}>
                  <Formik
                    initialValues={isNew ? initialValues : storeProps}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={async (values) => updateCategory(values)}
                    render={({
                      values,
                      errors,
                      touched,
                      handleReset,
                      handleSubmit,
                      setFieldValue,
                    }) => {
                      return (
                        <Form noValidate onSubmit={handleSubmit}>
                              <TextField
                                fullWidth
                                multiline
                                InputProps={{
                                  rows: 3
                                }}
                                label="Key"
                                placeholder="Key"
                                style={{ width: "100%", marginTop: 10 }}
                                onChange={(event)=>handleKeyChange(event)}
                                value = {key}
                                disabled={!isNew}
                              />
                          <FieldArray
                            name="label"
                            render={({ insert, remove, push }) => (
                              <>
                                {values.label.map((label, index, self) => (
                                  <>
                                      <MDBox container spacing={2} py={2}>
                                      {isNew &&
                                        values.label.length <
                                          storeProps.languageList.length && (
                                            <MDBox textAlign="right">
                                              <MDButton
                                                p={3}
                                                size="small"
                                                variant="text"
                                                color="info"
                                                sx={{padding: "0px", fontSize: "14px", letterSpacing: "0.5px", fontWeight: "600"}}
                                                onClick={() =>
                                                  push({
                                                    key: "",
                                                    local: "",
                                                    name: "",
                                                    languageName: "",
                                                  })
                                                }
                                              >
                                                Add Language
                                              </MDButton>
                                            </MDBox>
                                        )}
                                        {values.label.length > 1 && (
                                          <MDBox textAlign="right">
                                            <MDButton
                                              variant="text"
                                              color="error"
                                              onClick={() => remove(index)}
                                              sx={{padding: "0px", fontSize: "14px", letterSpacing: "0.5px", fontWeight: "600"}}
                                            >
                                              Remove
                                            </MDButton>
                                          </MDBox>
                                            
                                          )}
                                        
                                          
                                            <Field name={`label`}>
                                              {({ field }) => (
                                                <>
                                                    <Autocomplete
                                                      getOptionLabel={(option) =>
                                                        option.languageName || ""
                                                      }
                                                      isOptionEqualToValue={(option, value) =>
                                                        option.local === value.local
                                                      }
                                                      value={field.value[index]}
                                                      onChange={(event, value) =>
                                                        handleChange(
                                                          event,
                                                          value,
                                                          index,
                                                          setFieldValue,
                                                          values
                                                        )
                                                      }
                                                      options={
                                                        isNew ? newLabel : storeProps.label
                                                      }
                                                      renderInput={(params) => (
                                                 
                                                        <TextField
                                                          {...params}
                                                          label="Language"
                                                        />
                                                      )}
                                                      disabled={(!isNew && !_isEmpty(label.languageName))}
                                                    />
                                                 
                                                </>
                                              )}
                                            </Field>
                                            {errors?.label?.[index]?.local &&
                                              // touched?.label?.[index]?.local &&
                                              showError(errors.label[index].local)}
                                          
                                            <Field name={`label.${index}.name`}>
                                              {({ field }) => (
                                                <TextField
                                                  fullWidth
                                                  multiline
                                                  label="Name"
                                                  InputProps={{
                                                      rows: 3
                                                  }}
                                                  placeholder="Name"
                                                  style={{ width: "100%", marginTop: 20 }}
                                                  onChange={field.onChange(field.name)}
                                                  value={field.value}
                                                />
                                              )}
                                            </Field>
                                            {errors?.label?.[index]?.name &&
                                              // touched?.label?.[index]?.name &&
                                              showError(errors.label[index].name)}
                                              
                                         
                                        </MDBox>
                                     
                                          
                                      
                                    {/* <Divider /> */}
                                  </>
                                ))}
                                <MDBox
                                  mt={2}
                                  display="flex"
                                  justifyContent={
                                    isNew && values.label.length < 2
                                      ? "flex-end"
                                      : "flex-end"
                                  }
                                >
                                  
                                  <MDBox>
                                    {values.label.length !== 0 && (
                                      <MDButton
                                        variant="outlined"
                                        color="success"
                                        type="submit"
                                        sx={{ marginRight: "15px" }}
                                      >
                                        Save
                                      </MDButton>
                                    )}
                                    <MDButton
                                      variant="outlined"
                                      color="error"
                                      onClick={handleClose}
                                    >
                                      Cancel
                                    </MDButton>
                                  </MDBox>
                                </MDBox>
                              </>
                            )}
                          />
                        </Form>
                      );
                    }}
                  />
                </DialogContent>
              </Dialog>

              <Dialog open={openConfirmation} onClose={handleConfirmationClose} fullWidth maxWidth="sm">
                <DialogTitle textAlign="center" sx={{ m: 0, px: 2, pt: 4 }}>
                  {"Are you sure you want to Label Language?"}
                </DialogTitle>
                <DialogActions textAlign="center" display="flex" sx={{ justifyContent:"center", pb:4 }}>
                  <MDButton
                    variant="outlined"
                    color="success"
                    onClick={deleteHoodCategory}
                    sx={{mr:1, minWidth:"125px"}}
                  >
                    Agree
                  </MDButton>
                  <MDButton
                    variant="outlined"
                    color="error"
                    onClick={handleConfirmationClose}
                    sx={{minWidth:"125px"}}
                  >
                    Disagree
                  </MDButton>
                  
                </DialogActions>
              </Dialog>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default LabelLanguage;
