import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import { Field, Form, Formik } from "formik";
import { get as _get, isEmpty as _isEmpty, omit as _omit } from "lodash";
import {
  renderTextField,
  renderRadioButton,
  renderCheckbox,
  renderSelectField,
} from "../../inputFields/formFields";
import { getPlaceDetailsById, updateThePlaceDetails,getPlaceCategoryList,getPlaceCategoryById, getPlaceCategoryListForDropdown, getPlaceCategoryByIdForDropdown } from "../../actions/places"
import { InputLabel } from "@mui/material";


const Place = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const hiddenFileInput = useRef(null)
  const [imageIndex, setImageIndex] = useState()
  const [selectedImageUrl, setSelectedImageUrl] = useState(null)
  const [overViewImages,setOverViewImages] = useState([])
  const [imageFiles, setImageFiles] = useState([])

  const storeProps = useSelector((state) => {
    return {
      data: _get(state,'places.placeDetails',{}),
      mainCategoryList: _get(state,'places.placeCategoryListForDropdown',[]),
      subCategoryList: _get(state,'places.placeCategoryDataForDropdown',[]),
    };
  });
  
 const initialValues = storeProps.data


useMemo(() => {
  if (!_isEmpty(_get(storeProps,'data.selected_images',null)) && _get(storeProps,'data.images',[]).indexOf(_get(storeProps,'data.selected_images','')) !== -1) {
      setImageIndex(_get(storeProps,'data.images',[]).indexOf(_get(storeProps,'data.selected_images','')))
  }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [_get(storeProps,'data.selected_images')]);


  useEffect(() => {
    if (params.id) {
      dispatch(getPlaceDetailsById(params.id))
      dispatch(getPlaceCategoryListForDropdown());
      // dispatch(getPlaceCategoryById())
    }
  }, [params, dispatch]);
 
  useEffect(() => {
    dispatch(getPlaceCategoryByIdForDropdown(storeProps.data.primaryCategoryCode))
  }, [storeProps.data])
 
 
  const updateTheDetails = (values) => {
    const data = {
      ..._omit(values, ['images']),
      id: params.id
    }

    if (selectedImageUrl !== null) {
      data.selected_images = selectedImageUrl
    }
    const formData = new FormData();
    formData.append('data', JSON.stringify(data))

    for (let i = 0; i < imageFiles.length; i++) {
      formData.append("files", imageFiles[i], imageFiles[i].name);
    }

    dispatch(updateThePlaceDetails(formData, navigate))
  }

  const setSelectedImages = (event) => {
    const files = Array.from(event.target.files);
    files.forEach(file => { 
      overViewImages.push(URL.createObjectURL(file))
    })
    setOverViewImages(overViewImages);
    setImageFiles([...imageFiles, ...event.target.files])

  }

const subCategoryList = storeProps.subCategoryList.subCategory

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                mb={0}
                variant="contained"
                bgColor='rgba(0, 0, 0, 0.0)'
                borderRadius="lg"
                // coloredShadow="info"
              >
                <MDTypography variant="h5" color="black">
                  Edit Place Details
                </MDTypography>
              </MDBox>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                // validationSchema={schema}
                onSubmit={async (values) => updateTheDetails(values)}
                >
                {({
                  handleSubmit,
                }) => {
                  return (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={3} pl={2} pr={2}>
                        <Grid item xs={12} md={6}>
                          <Field
                            name="name"
                            component={renderTextField}
                            label="Place Name"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            name="address"
                            component={renderTextField}
                            label="Address"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            name="vicinity"
                            component={renderTextField}
                            label="Vicinity"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Field
                            name="internationalPhoneNumber"
                            component={renderTextField}
                            label="International Phone Number"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            name="phoneNumber"
                            component={renderTextField}
                            label="Phone Number"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MDBox display="flex" alignItems="center">
                          
                          <Field
                            name="rating"
                            component={renderTextField}
                            label="App Rating"
                            fullWidth
                            type="number"
                            InputProps={{
                              readOnly: true,
                             }}
                          />
                          <Field
                            name="totalRating"
                            component={renderTextField}
                            label="App Total Rating"
                            fullWidth
                            type="number"
                            style={{ marginLeft: 10 }}
                            InputProps={{
                              readOnly: true,
                             }}
                          />
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} md={6}>
                        {/* <a href={_get(initialValues,'website','')} target="_blank" rel="noreferrer"> */}
                          <Field
                            name="website"
                            component={renderTextField}
                            label="Place Website"
                            fullWidth
                            type="text"
                          />
                          {/* </a> */}
                        </Grid>
                        
                  {/*       <Grid item xs={12} md={6}>
                          <MDBox display="flex" alignItems="center">
                          
                          <Field
                            name="googleRating"
                            component={renderTextField}
                            label="Google Rating"
                            fullWidth
                            type="number"
                            InputProps={{
                              readOnly: true,
                             }}
                          />
                          <Field
                            name="googleTotalRating"
                            component={renderTextField}
                            label="Google Total Rating"
                            fullWidth
                            type="number"
                            style={{ marginLeft: 10 }}
                            InputProps={{
                              readOnly: true,
                             }}
                          />
                          </MDBox>
                        </Grid> */}
                    <Grid item xs={12} sm={6} xl={3}>
                           <InputLabel id="label">Category</InputLabel>
                               <Field
                                name="primaryCategoryCode"
                                label="Category"
                                component={renderSelectField}
                                menuItemArray={storeProps.mainCategoryList}
                                handleChange={(value) => {
                                  console.warn("value",value)
                                   dispatch(getPlaceCategoryByIdForDropdown(value))
                                }}
                                sx={{width:'100%'}}
                              />   
                        </Grid>
                         <Grid item xs={12} sm={6} xl={3}>
                            <InputLabel id="label">Sub Category</InputLabel>
                            <Field
                              name="secondaryCategoryCode"
                              label="Sub Category"
                              component={renderSelectField}
                              menuItemArray={subCategoryList|| []}
                              sx={{width:'100%'}}
                              handleChange={(value) =>value}
                            />
                        </Grid> 
                        <Grid item xs={12} md={6}>
                          {/* <a href={_get(initialValues,'mapURL','')} target="_blank" rel="noreferrer"> */}
                          <Field
                            name="mapURL"
                            component={renderTextField}
                            label="Place mapURL"
                            fullWidth
                            type="text"
                          //   InputProps={{
                          //     className: {
                          //       "& .MuiInputBase-root": {
                          //         color: 'red'
                          //     }
                          //     }
                          // }}
                          // InputProps={{
                          //       classes: {
                          //           input: { color: 'red' }
                          //       }
                          //   }}
                          //   //disabled={appUserView}
                          />
                          {/* </a> */}
                        </Grid>
                        
                        <Grid item xs={12} md={6}>
                          <MDBox display="flex" alignItems="center">
                          
                          <Field
                            name="latitude"
                            component={renderTextField}
                            label="Place Latitude"
                            fullWidth
                            type="number"
                            InputProps={{
                              readOnly: true,
                             }}
                          />
                          <Field
                            name="longitude"
                            component={renderTextField}
                            label="Place Longitude"
                            fullWidth
                            type="number"
                            style={{ marginLeft: 10 }}
                            InputProps={{
                              readOnly: true,
                             }}
                          />
                          </MDBox>
                        </Grid>
                       <Grid item xs={12} md={6}>
                        <MDBox display='flex' alignItems='center'>
                          <MDTypography variant="text" color="black" fontSize="16px">
                            Is Active Place: 
                            </MDTypography>
                            <Field
                              name="isActive"
                              component={renderCheckbox}
                              //sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                               size="medium"
                               color="orange"
                            />
                      </MDBox>
                      </Grid>
                      </Grid>
                      <input
                        ref={hiddenFileInput}
                        onChange={(event)=> setSelectedImages(event)}
                        style={{ display: 'none' }}
                        type="file"
                        multiple
                        accept="image/*"
                      />
                      {_get(initialValues, 'images', []).length > 0 &&
                        <>
                            <MDTypography variant="h6" color="black" mt={4} ml={2} >
                              Select Google Place images
                            </MDTypography>
                            {initialValues.images.map((url, index) => (
                              initialValues.images.length > 0 &&
                              <div style={{position: 'relative', display: 'inline-block'}}>
                              <CardMedia
                                key={index}
                                component="img"
                                src={url}
                                style={{
                                  height: "300px",
                                  backgroundSize: "contain",
                                  width: '100%',
                                  objectFit: 'contain'
                                }} />
                                <Field
                                    name={`isImageSelected${index}`}
                                    checked={index === imageIndex}
                                    component={renderRadioButton}
                                    style={{ position: 'absolute', top: '15px', right:'10px'}}
                                    color='error'
                                    size="medium"
                                    handleChange={()=>{setImageIndex(index); setSelectedImageUrl(url)}}
                                  />
                                  </div>
                              ))}
                        </>
                      }

                      {overViewImages.length > 0 &&
                        <>
                          <MDTypography variant="h6" color="black" mt={4} ml={2} >
                            Preview selected images
                          </MDTypography>
                          {overViewImages.map((url, inx) => (
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                              <CardMedia
                                key={inx}
                                component="img"
                                src={url}
                                style={{
                                  height: "200px",
                                  backgroundSize: "contain",
                                  width: '50%',
                                  objectFit: 'contain'
                                }}
                              />
                            </div>
                          ))}
                        </>
                      }
             
             
                      <MDBox p={2} textAlign="right" >
                            <MDButton
                              variant="outlined"
                              color="success"
                              sx={{ marginRight: "15px" }}
                              onClick={()=>hiddenFileInput.current.click()}
                            >
                              Add Image
                            </MDButton>
                          <MDButton
                            variant="outlined"
                            color="success"
                            type="submit"
                            sx={{ marginRight: "15px" }}
                          >
                            Save
                          </MDButton>
                        <MDButton
                          variant="outlined"
                          color="error"
                          onClick={() => navigate("/places")}
                        >
                          Cancel
                        </MDButton>
                      </MDBox>
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Place;
