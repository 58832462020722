import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "../../examples/Cards/SettingCards";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={0} pb={3} sx={"min-height: 80vh; display:flex; align-items:center"}>
        <MDBox width="100%">
          <MDBox sx={"display:flex; align-items:center; justify-content:center; flex-wrap: wrap"}>
            <MDBox width="350px" m={1} onClick={() => navigate("/settings/general")} sx={{ cursor: "pointer"}} >
              <ComplexStatisticsCard
                color="black"
                icon="settings_application_icon"
                title="General"
              />
            </MDBox>         
            <MDBox width="350px" m={1} onClick={() => navigate("/settings/places")} sx={{ cursor: "pointer" }}>
              <ComplexStatisticsCard
                icon="place"
                title="Places"
                color="purple"
              />
            </MDBox>
          </MDBox>       
          <MDBox sx={"display:flex; align-items:center; justify-content:center; flex-wrap: wrap;"}>
            <MDBox width="350px" m={1} onClick={() => navigate("/settings/hoods")} sx={{ cursor: "pointer" }}>
              <ComplexStatisticsCard
                color="purple"
                icon="groups_icon"
                title="Hoods"
              />
            </MDBox>       
            <MDBox width="350px" m={1} onClick={() => navigate("/settings/labelLanguage")} sx={{ cursor: "pointer" }}>
              <ComplexStatisticsCard
                color="purple"
                icon="label_icon"
                title="LabelLanguage"
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default Settings;
