import Grid from "@mui/material/Grid";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";

import LabelLanguage from "./labelLanguage";

const LabelLanguageSettings = () => {
  return (
    <DashboardLayout>
    <DashboardNavbar />
    <Grid container spacing={2} p={2}>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          paddingRight: "10px",
        }}
      >
        <LabelLanguage />
      </Grid>
      {/* <Grid
        item
        xs={12}
        md={6}
        style={{
          paddingLeft: "10px",
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0.08)",
          borderWidth: "0 0 0 1px",
        }}
      >
           <Places />
      </Grid> */}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          paddingRight: "10px",
        }}
      >
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          paddingLeft: "10px",
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0.08)",
          borderWidth: "0 0 0 1px",
        }}
      >
      </Grid>
    </Grid>
    </DashboardLayout>
  );
};

export default LabelLanguageSettings;